<template>
  <div class="accountAff">
    <div class="title">
      <h2>User-Aff Setting</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" :model="filter" label-width="100px">
              <el-form-item label="Account ID">
                <el-input v-model="filter.accountId" placeholder="account id here ..."></el-input>
              </el-form-item>
              <el-form-item label="Account Name" label-width="120px">
                <el-input
                  v-model="filter.accountName"
                  placeholder="account name regex here ..."
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getUserAffSourceList(1)">In Browser</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="roleRightsList"
              highlight-current-row
            >
              <el-table-column label="Account ID" prop="accountId" align="center"></el-table-column>
              <el-table-column label="account" prop="username" align="center"></el-table-column>
              <el-table-column label="Open Source" prop="isOpenAllAdv" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.isOpenAllAdv == 1">Yes</span>
                  <span v-else>No</span>
                </template>
              </el-table-column>
              <el-table-column label="Open Affiliate" prop="isOpenAllAdv" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.isOpenAllAff == 1">Yes</span>
                  <span v-else>No</span>
                </template>
              </el-table-column>
              <el-table-column label="Source" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.isOpenAllAdv == 1">ALL</span>
                  <span v-else v-html="filterSources(scope.row.srcIds)"></span>
                </template>
              </el-table-column>
              <el-table-column label="Aff" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.isOpenAllAff == 1">ALL</span>
                  <span v-else v-html="filterAff(scope.row.affIds)"></span>
                </template>
              </el-table-column>
              <el-table-column label="Action" align="center">
                <template slot-scope="scope">
                  <el-button type="primary" @click="updateClick(scope.row)">Update</el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
            <el-dialog title="Update user" width="30%" :visible.sync="updateVisible">
              <el-form :inline="true" :model="updateUser" label-width="120px" label-position="left">
                <el-form-item label="Choose Affiliates">
                  <el-select
                    v-model="updateUser.affiliateIds"
                    clearable
                    filterable
                    multiple
                    placeholder="请选择"
                    style="width:210px;"
                  >
                    <el-option
                      v-for="item in affiliateList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Choose Sources">
                  <el-select
                    v-model="updateUser.sourceIds"
                    clearable
                    filterable
                    multiple
                    placeholder="请选择"
                    style="width:210px;"
                  >
                    <el-option
                      v-for="item in sourceList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="updateVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmUpdateClick">确 定</el-button>
              </span>
            </el-dialog>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { getPermissionList, updatePermission } from 'api/account/permission';
  import { getSourceList } from 'api/source/index';
  import { getAffiliateList } from 'api/affiliate/index';
  import Pagination from '@/components/pagination';
  import { myMixin } from '@/mixins/mixins.js';
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        updateVisible: false,
        updateUser: {},
        filter: {
          accountName: null,
          accountId: null,
        },
        affiliateTmpList: [],
        affiliateList: [],
        sourceTmpList: [],
        sourceList: [],
        total: 0,
        pageSize: 20,
        currentPage: 1,
        roleRightsList: [],
      };
    },
    mounted() {
      this.getaffiliateList();
      this.getsourceList();
      this.getUserAffSourceList();
    },
    methods: {
      getaffiliateList() {
        getAffiliateList().then((response) => {
          if (response.code == 200) {
            this.affiliateTmpList = response.result;
            this.affiliateTmpList.forEach((item, i) => {
              item = this.affiliateTmpList[i];
              this.affiliateList.push({
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            });
          }
        });
      },
      getsourceList() {
        getSourceList().then((response) => {
          if (response.code === 200) {
            this.sourceTmpList = response.result;
            this.sourceTmpList.forEach((item, i) => {
              item = this.sourceTmpList[i];
              this.sourceList.push({
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            });
          }
        });
      },
      getUserAffSourceList(page) {
        let param = {
          ...this.filter,
          page: page || this.currentPage,
          pageSize: this.pageSize,
        };
        getPermissionList(param).then((response) => {
          if (response.code == 200) {
            this.roleRightsList = response.result;
            this.total = response.total;
          }
        });
      },
      filterAff(affiliates) {
        let html = '';
        if (Array.isArray(affiliates) && affiliates.length > 0) {
          this.affiliateTmpList.forEach((item) => {
            if (affiliates.includes(item.id)) {
              html += item.company + '(' + item.id + ')' + ' ';
            }
          });
        }
        return html;
      },
      filterSources(sources) {
        let html = '';
        if (Array.isArray(sources) && sources.length > 0) {
          this.sourceTmpList.forEach((item) => {
            if (sources.includes(item.id)) {
              html += item.company + '(' + item.id + ')' + ' ';
            }
          });
        }
        return html;
      },
      updateClick(row) {
        this.updateUser = {
          accountId: '',
          affiliateIds: [],
          sourceIds: [],
        };
        this.updateUser.accountId = row.accountId;
        if (row.affIds != null && row.affIds.length > 0) {
          this.updateUser.affiliateIds = row.affIds;
        }
        if (row.srcIds != null && row.srcIds.length > 0) {
          this.updateUser.sourceIds = row.srcIds;
        }
        this.updateVisible = true;
      },
      confirmUpdateClick() {
        updatePermission({ ...this.updateUser }).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Success',
              type: 'success',
            });
            this.getUserAffSourceList();
            this.updateVisible = false;
          } else {
            this.$message.error('Update Error' + response.message);
          }
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchListClick();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
