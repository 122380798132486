import request from 'utils/request';

const api = {
  PERMISSION_LIST: '/api/permission/list',
  PERMISSION_UPDATE: '/api/permission/updateAffSource',
};
/**
 * 获取有权限的资源列表
 * @param {*} query {accountId:int,accountName:string,page:int,pageSize:int}
 */
export function getPermissionList(query) {
  return request({
    url: api.PERMISSION_LIST,
    method: 'get',
    params: query,
  });
}
/**
 * 修改用户资源
 * @param {*} data {accountId:int,sourceIds:Array<int>,affiliateIds:Array<int>}
 */
export function updatePermission(data) {
  return request({
    url: api.PERMISSION_UPDATE,
    method: 'put',
    data,
  });
}
